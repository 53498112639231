import { Component } from "react";
import styled from "styled-components";

// Animations
import gsap from "gsap";
import ScrollMagic from "scrollmagic";

import { Color, rem, Font, responsive, Opacity } from "../utils/style";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Container from "../components/Container";
import MagicLink from "../components/MagicLink";
import Text from "./Text";
import Row from "../components/Row";

const StyledLink = styled(MagicLink)`
  transition: opacity 0.2s;
  border-bottom: 1px solid ${Color.ritualBlue};

  &:hover {
    opacity: ${Opacity.light};
  }
`;

const Section = styled.section`
  position: relative;
  margin-top: 40px;

  ${responsive.md`
    margin-top: 80px;
  `};

  ${responsive.lg`
    margin-top: 82px;
  `};
`;

const HalfColumn = styled.div.attrs({
  className:
    "col-12 col-sm-6 d-flex flex-column align-items-center justify-content-center",
})`
  &.first {
    margin-bottom: 80px;

    ${responsive.sm`
      margin-top: 35px;
      margin-bottom: 137px;
    `};

    ${responsive.md`
      margin-top: 63px;
      margin-bottom: 232px;
    `};

    ${responsive.lg`
      margin-top: 135px;
      margin-bottom: 350px;
    `};
  }

  &.second {
    margin-bottom: 80px;

    ${responsive.sm`
      margin-bottom: 116px;
    `};

    ${responsive.md`
      margin-bottom: 245px;
    `};

    ${responsive.lg`
      margin-bottom: 350px;
    `};
  }

  &.third {
    padding-bottom: 80px;

    ${responsive.sm`
      padding-bottom: 94px;
    `};

    ${responsive.md`
      padding-bottom: 140px;
    `};

    ${responsive.md`
      padding-bottom: 254px;
    `};
  }

  h1,
  p {
    text-align: center !important;
    display: block;
    margin: 0 auto 0;

    ${responsive.sm`
      max-width: 340px;
    `};

    ${responsive.md`
      max-width: 374px;
    `};

    ${responsive.lg`
      max-width: 470px;
    `};

    span {
      ${Font.dutch};
      font-style: italic;
    }
  }

  h1 {
    ${Font.circular};
    font-size: ${rem(30)};
    line-height: ${rem(36)};
    letter-spacing: -0.4px;
    margin-bottom: 16px;

    ${responsive.md`
      font-size: ${rem(48)};
      line-height: ${rem(54)};
      letter-spacing: -1.4px;
      margin-bottom: 24px;
    `};
  }

  p {
    ${Font.dutch};
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    margin: 0;

    ${responsive.md`
      font-size: ${rem(18)};
      line-height: ${rem(28)};
    `};
  }
`;

const ImageColumn = styled.div.attrs({
  className:
    "col-12 d-sm-none col-sm-6 order-sm-1 d-flex align-items-center justify-content-center",
})`
  .gatsby-image-wrapper {
    width: calc(100vw - 30px);
    height: calc(100vw - 30px);
    margin-bottom: 24px;
  }
`;

const StickyColumn = styled.div.attrs({
  className: "d-none d-sm-block",
})`
  width: 50vw;
  position: absolute;
  right: 0 !important;
  top: 0;
  display: flex;
  justify-content: flex-start;
  padding-left: 5px;

  ${responsive.md`
    padding-left: 0;
  `};

  .scrollmagic-pin-spacer {
    min-width: 0 !important;
  }
`;

const ImageWrapper = styled.div.attrs({
  className: "image-wrapper",
})`
  .sticky-image {
    display: none;
    width: 340px;
    height: 340px;
    margin: 0 !important;

    img {
      transition: none !important;
    }

    &:first-of-type {
      display: block;
    }

    ${responsive.md`
      width: 454px;
      height: 454px;
    `};

    ${responsive.lg`
      width: 570px;
      height: 570px;
    `};
  }
`;

export default class ApproachImageAnimation extends Component {
  constructor(props) {
    super(props);
    this.scene = null;
    this.stickyColumn = null;
    this.sceneElement = null;

    this.recalculateDuration = this.recalculateDuration.bind(this);
  }

  componentDidMount() {
    // Initialize it, then re-initialize after fonts loaded
    setTimeout(() => {
      this.initializeAnimation();
    }, 500);
    window.addEventListener("resize", this.recalculateDuration);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.recalculateDuration);
    this.scene?.destroy(true);
  }

  initializeAnimation() {
    if (this.scene) {
      this.scene.destroy(true);
    }
    if (ScrollMagic && this.stickyColumn) {
      let duration = this.calculateSceneDuration();
      let sceneOptions = {
        triggerElement: this.imageWrapper,
        triggerHook: 0,
        duration: duration - 60,
        offset: this.getOffset(),
      };
      this.scene = new ScrollMagic.Scene(sceneOptions)
        .setPin(this.imageWrapper)
        .setTween(this.animationTween())
        .addTo(this.props.controller);
    }
  }

  calculateSceneDuration() {
    return this.sceneElement.clientHeight - this.imageWrapper.clientHeight - 60;
  }

  getOffset() {
    return window.matchMedia("(min-width: 1025px)").matches ? -200 : -150;
  }

  recalculateDuration() {
    if (this.scene) {
      let offset = this.getOffset();
      this.scene.offset(offset);
      this.scene.duration(this.calculateSceneDuration());
      this.scene.update(true);
    }
  }

  animationTween() {
    let timeline = gsap.timeline();

    timeline
      .to(".sticky01", {
        duration: 0.33,
        css: { display: "none" },
      })
      .to(".sticky02", {
        duration: 0.33,
        css: { display: "block" },
      })
      .set(".sticky02", {
        css: { display: "none" },
      })
      .to(".sticky03", {
        duration: 0.33,
        css: { display: "block" },
      });
    return timeline;
  }

  render() {
    let { images } = this.props;

    return (
      <Section>
        <StickyColumn ref={(r) => (this.stickyColumn = r)}>
          <ImageWrapper ref={(r) => (this.imageWrapper = r)}>
            <GatsbyImage
              className="sticky-image sticky01"
              image={getImage(images[0])}
              alt={images[0].description}
              loading="eager"
            />
            <GatsbyImage
              className="sticky-image sticky02"
              image={getImage(images[1])}
              alt={images[1].description}
              loading="eager"
            />
            <GatsbyImage
              className="sticky-image sticky03"
              image={getImage(images[2])}
              alt={images[2].description}
              loading="eager"
            />
          </ImageWrapper>
        </StickyColumn>

        <Container ref={(r) => (this.sceneElement = r)}>
          <Row>
            <ImageColumn>
              <GatsbyImage
                image={getImage(images[0])}
                alt={images[0].description}
                loading="eager"
              />
            </ImageColumn>
            <HalfColumn className="first">
              <h1>
                <Text
                  id="approach.imageanim.heading1.first"
                  defaultMessage="For skeptics,"
                />
                <br />
                <Text
                  id="approach.imageanim.heading1.second"
                  defaultMessage="{em} skeptics."
                  values={{
                    em: (
                      <span>
                        <Text
                          id="approach.imageanim.heading1.second-em"
                          defaultMessage="by"
                        />
                      </span>
                    ),
                  }}
                />
              </h1>
              <p>
                <Text
                  id="approach.imageanim.copy1.main"
                  defaultMessage={`We’re not about pseudoscience and half-truths. Health fads make
                  our eyes roll. So, when we set out to develop {link}, we wanted something simple, clean, and backed by science. We
                  challenged some of the top scientists to reimagine the daily
                  multivitamin for women. The result is everything we think a
                  multivitamin should be.`}
                  values={{
                    link: (
                      <StyledLink to="/products/essential-for-women-multivitamin">
                        <Text
                          id="approach.imageanim.copy1.link"
                          defaultMessage="Essential for Women"
                        />
                      </StyledLink>
                    ),
                  }}
                />
              </p>
            </HalfColumn>
          </Row>
          <Row>
            <ImageColumn>
              <GatsbyImage
                image={getImage(images[1])}
                alt={images[1].description}
                loading="eager"
              />
            </ImageColumn>
            <HalfColumn className="second">
              <h1>
                <Text
                  id="approach.imageanim.heading2"
                  defaultMessage="We’re Made Traceable®"
                />
              </h1>
              <p>
                <Text
                  id="approach.imageanim.copy2.main"
                  defaultMessage={`For us, reimagining the multivitamin has always been about more
                  than just combining great ingredients — it’s working
                  with companies that care as much about their supply chain as we
                  do. We’ve worked tirelessly to find the right partners worldwide
                  with the {link} that meet our Made Traceable® standards. At Ritual, true
                  transparency isn’t just something printed on a label. We’re
                  committed to sharing the choices we made that impact you and the
                  planet. {report}`}
                  values={{
                    link: (
                      <StyledLink to="/ingredients">
                        <Text
                          id="approach.imageanim.copy2.link"
                          defaultMessage="ingredients"
                        />
                      </StyledLink>
                    ),
                    report: (
                      <StyledLink to="https://impact.ritual.com/2023.pdf">
                        <Text
                          id="approach.imageanim.copy2.report"
                          defaultMessage="See our 2023 Impact Report."
                          />
                      </StyledLink>
                    )
                  }}
                />
              </p>
            </HalfColumn>
          </Row>
          <Row>
            <ImageColumn>
              <GatsbyImage
                image={getImage(images[2])}
                alt={images[2].description}
                loading="eager"
              />
            </ImageColumn>
            <HalfColumn className="third">
              <h1>
                <Text
                  id="approach.imageanim.heading3"
                  defaultMessage="No B.S."
                />
              </h1>
              <p>
                <Text
                  id="approach.imageanim.copy3.main"
                  defaultMessage={`We care so much about what we put in and on our bodies, but when
                  it comes to vitamins, we still rely on blind faith. Too often,
                  companies make their process and formulations intentionally
                  confusing to keep their customers from knowing what’s really
                  going on. We’re proud of the product we’ve put together with no
                  unnecessary ingredients and with nutrients in their best forms.
                  So go ahead and look under the hood—we want you to. {link}`}
                  values={{
                    link: (
                      <StyledLink to="/clinical-study">
                        <Text
                          id="approach.imageanim.copy3.link"
                          defaultMessage="See our clinical study."
                        />
                      </StyledLink>
                    ),
                  }}
                />
              </p>
            </HalfColumn>
          </Row>
        </Container>
      </Section>
    );
  }
}
