import { Component } from "react";
import styled from "styled-components";
import Text from "../Text";

// Utils
import { rem, Font, responsive } from "../../utils/style";

// Components
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Styled Elements
const ScienceTeamWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;

  ${responsive.sm`
    flex-direction: row;
  `};
`;

const ScienceTeamMember = styled.div`
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
  position: relative;
  margin: 0;
  margin-bottom: 40px;

  ${responsive.sm`
    flex: 0 0 auto;
    margin: 0 25px;
    max-width: 160px;
  `};

  ${responsive.md`
    margin: 0 33px;
    max-width: 214px;
  `};

  ${responsive.lg`
    margin: 0 40px;
    max-width: 270px;
  `};

  &:last-of-type {
    margin-bottom: 0;
  }

  p {
    ${Font.circular};
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    font-weight: 500;
    text-align: center;
    margin: 0;

    ${responsive.sm`

    `};

    ${responsive.md`
      font-size: ${rem(18)};
      line-height: ${rem(28)};
    `};

    &:first-of-type {
      ${Font.circular};
      margin-bottom: 4px;

      ${responsive.sm`
        margin-bottom: 8px;
      `};
    }

    &:last-of-type {
      ${Font.dutch};
    }
  }
`;

const ScienceTeamMemberPhoto = styled.div`
  transform: translate(0, 0);
  width: 182px;
  height: 182px;
  border-radius: 50%;
  background-color: #ccc;
  margin-bottom: 16px;
  overflow: hidden;

  ${responsive.sm`
    width: 160px;
    height: 160px;
  `};
  ${responsive.md`
    width: 214px;
    height: 214px;
    margin-bottom: 24px;
  `};
  ${responsive.lg`
    width: 270px;
    height: 270px;
  `};
`;

export default class ScienceTeam extends Component {
  render() {
    let { nima, noe, mastaneh } = this.props.team;

    return (
      <ScienceTeamWrapper>
        <ScienceTeamMember>
          <ScienceTeamMemberPhoto>
            <GatsbyImage
              image={getImage(nima)}
              alt={nima.description}
              loading="eager"
              style={{
                borderRadius: "50%",
                overflow: "hidden",
              }}
            />
          </ScienceTeamMemberPhoto>
          <p>Dr. Nima Alamdari, PhD</p>
          <p>
            <Text
              id="approach.science-team.cso"
              defaultMessage="Chief Scientific Officer"
            />
            <br />
            <Text
              id="approach.science-team.cso-note"
              defaultMessage="(Physiology)"
            />
          </p>
        </ScienceTeamMember>
        <ScienceTeamMember>
          <ScienceTeamMemberPhoto>
            <GatsbyImage
              image={getImage(mastaneh)}
              alt={mastaneh.description}
              loading="eager"
              style={{
                borderRadius: "50%",
                overflow: "hidden",
              }}
            />
          </ScienceTeamMemberPhoto>
          <p>Dr. Mastaneh Sharafi, PhD, RD</p>
          <p>
            <Text
              id="approach.science-team.director-science"
              defaultMessage="VP, Scientific & Clinical Affairs"
            />
            <br />
            <Text
              id="approach.science-team.director-science-note"
              defaultMessage="(Nutritional Sciences)"
            />
          </p>
        </ScienceTeamMember>
      </ScienceTeamWrapper>
    );
  }
}
