import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Component } from "react";
import styled from "styled-components";
import intl from "../../services/intl";
import {
  atLeast,
  Color,
  Font,
  media,
  Opacity,
  rem,
  responsive,
} from "../../utils/style";
import { CarouselChild, CarouselContainer } from "../Carousel.New";
import Container from "../Container";
import Row from "../Row";

// Styled Components
const ExpertsArea = styled.section.attrs({
  "aria-labelledby": "experts-header",
})`
  background: rgba(233, 238, 241, ${Opacity.light});
  padding-bottom: 120px;
  position: relative;
  overflow: hidden;

  ${media.tablet`
    padding-bottom: 80px;
  `};
`;

const ExpertContainer = styled(Container).attrs({
  id: "experts_container",
})`
  position: relative;
  z-index: 1;

  ${media.tablet`
    max-width: 100% !important;
    width: 100% !important;
  `};
`;

const Header = styled.h2.attrs({ id: "experts-header" })`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(48)};
  line-height: ${rem(54)};
  font-weight: 500 !important;
  text-align: center !important;
  letter-spacing: -1.4px;
  margin: 120px 0 24px;

  ${media.tablet`
    font-size: ${rem(30)};
    line-height: ${rem(36)};
    letter-spacing: -0.4px;
    margin: ${rem(80)} 0 ${rem(16)};
  `}
`;

const Subhead = styled.p.attrs({
  className: "col-12 col-sm-8 offset-sm-2",
})`
  ${Font.dutch}
  color: ${Color.ritualBlue};
  font-size: ${rem(18)};
  text-align: center;
  letter-spacing: 0;
  line-height: ${rem(28)};
  margin-bottom: ${rem(56)};

  ${media.tablet`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    margin-bottom: ${rem(40)};
  `}
`;

const ExpertColumn = styled.div.attrs({
  className: "col-12",
})`
  .slider-list {
    touch-action: initial !important;
  }

  .slider-slide {
    display: flex !important;
    justify-content: center !important;
  }
`;

const ExpertCard = styled.div`
  min-width: 250px;
  max-width: 302px;
  background: #ffffff;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  padding: 40px 24px;
  outline: none;
  cursor: default;
  height: 100%;
  width: 100%;
  ${responsive.sm`
    min-width: 294px;
    max-width: 348px;
  `};
  ${responsive.md`
    max-width: 294px;
  `};
  ${responsive.lg`
    max-width: 360px;
  `};

  svg {
    width: 14px;
    height: 12px;
    position: absolute;
    top: 40px;
    left: calc(50% - 7px);
  }
`;

const QuoteImageWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 16px 0 16px;
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
`;

const QuoteImage = styled(GatsbyImage)`
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  width: 80px;
  height: 80px;

  // Workaround for WebKit bug:
  // https://discourse.webflow.com/t/overflow-hidden-round-corners-not-working-on-safari/67805/5
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
`;

const QuoteAuthor = styled.h3`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: 500;
  text-align: center;
  letter-spacing: 0;
  margin: 0 0 8px;
  text-align: center !important;
`;

const QuoteAuthorTitle = styled.p`
  ${Font.dutch}
  color: ${Color.ritualBlue};
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  font-style: italic;
  text-align: center !important;
  margin: 0;
  min-height: ${rem(48)};
`;

export default class Experts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slidesPresented: 3,
    };
  }

  componentDidMount() {
    this.setVisibleSlides();
    window.addEventListener("resize", this.setVisibleSlides);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setVisibleSlides);
  }

  setVisibleSlides = () => {
    let slidesPresented = 1;
    if (atLeast.tablet() && !atLeast.desktop()) {
      slidesPresented = 2;
    } else if (atLeast.desktop()) {
      slidesPresented = 3;
    }
    this.setState({
      slidesPresented,
    });
  };

  render() {
    const defaultExpertsTitle = intl.t(
      "templates.product.experts-title",
      "Meet Our In-House Scientists and Expert Council",
    );

    const defaultExpertsSubhead = intl.t(
      "templates.product.experts-subhead",
      `We work with a lot of exceptional and thoughtful people—like the scientists, nutritionists, and medical doctors on our Scientific Advisory Board, who help guide our product innovation and clinical research.`,
    );
    const { slidesPresented } = this.state;
    const {
      children,
      expertsTitle = defaultExpertsTitle,
      expertsSubhead = defaultExpertsSubhead,
      experts,
    } = this.props;
    return (
      <ExpertsArea>
        <ExpertContainer>
          <Header>{expertsTitle}</Header>
          <Subhead>{expertsSubhead}</Subhead>
          <Row>
            <ExpertColumn>
              <CarouselContainer slidesPresented={slidesPresented} arrowButtons>
                {experts.map((e, i) => {
                  const { name, image, title, titleSubhead } = e;
                  const externalTitle = e.externalTitle || title;
                  const firstClass = i === 0 ? "first" : "";
                  const lastClass = i === experts.length - 1 ? "last" : "";

                  const authorTitle = titleSubhead
                    ? `${externalTitle} (${titleSubhead})`
                    : externalTitle;

                  return (
                    <CarouselChild index={i} key={i}>
                      <ExpertCard
                        index={i}
                        className={`${firstClass} ${lastClass}`}
                      >
                        <QuoteImageWrapper index={i}>
                          <QuoteImage
                            index={i}
                            style={{
                              borderRadius: "50%",
                              overflow: "hidden",
                            }}
                            alt={name}
                            image={getImage(image)}
                          />
                        </QuoteImageWrapper>
                        <QuoteAuthor index={i}>{name}</QuoteAuthor>
                        <QuoteAuthorTitle index={i}>
                          {authorTitle}
                        </QuoteAuthorTitle>
                      </ExpertCard>
                    </CarouselChild>
                  );
                })}
              </CarouselContainer>
            </ExpertColumn>
          </Row>
        </ExpertContainer>
        {children}
      </ExpertsArea>
    );
  }
}
